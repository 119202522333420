import { url } from '@/api/base-url'

export function requestSendEmail (email) {
  return url.get('auth/recovery?email=' + email)
}

export function requestRecoveryPassword (body) {
  return url.post('auth/recovery', {
    ...body
  })
}
