<template>
  <div class="courses-modal" @click="closeModal">
    <transition name="modal">
      <div class="modal-container">
        <div class="modal-wrapper" ref="modal">
         <button-close v-if="buttonClose" class="btn-close" @close="$emit('close-modal', $event)" />
          <h3 class="modal-title" v-html="ModalTitle" ></h3>
          <slot>
            <button :style="{backgroundColor: buttonTheme}" @click="$emit('confirm')" class="btn-yes-ok"><slot name="textButton">Sim</slot></button>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal2',
  components: {
    'button-close': require('@/components shared/Modal/button-close.vue').default
  },
  props: {
    buttonTheme: {
      type: String,
      default: '#342041'
    },
    buttonClose: {
      type: Boolean,
      default: false
    },
    ModalTitle: {
      type: String,
      default: 'Pretende receber mais informações sobre este Riser?'
    }
  },
  methods: {
    closeModal (e) {
      const { modal } = this.$refs
      if (!modal) return
      if (!modal.contains(e.target)) {
        this.$emit('close-modal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .courses-modal{
    width: 100%;
    height: 100%;
    position: absolute;
    &.Parceiro {
      .btn-yes-ok {
        background: #26A0A5;
        &:hover {
          background: #3cd1d6;
        }
      }
    }
  }
  .modal-container {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #34204122 0% 0% no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    transition: opacity 0.3s ease;
    @media(max-width:768px){
      backdrop-filter: blur(11px);
      -webkit-backdrop-filter: blur(11px);
    }
    .btn-close {
      right: 20px;
      @media screen and (min-768px) {
        right: -50px;
      }
    }
  }
  .moadal-title{
    max-width: 250px;
  }
  .btn-yes-ok{
    border: none;
    padding: 10px 40px;
    margin-top: 30px;
    background-color: #342041;
    cursor: pointer;
    border-radius: 6px;
    color: #fff;
    &:hover{
      background-color: #551948;
    }
    @media screen and (min-width: 768px){
      width: 250px
    }
  }
  .modal-wrapper {
    max-width: 1080px;
    width: 400px;
    max-height: 100vh;
    min-height: 400px;
    background: #f2f2f2dc;
    display: flex;
    box-shadow: 6px 6px 15px #00000015;
    border-radius: 22px;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    @media(max-width:768px){
      padding: 20px;
      margin: auto auto;
      width: 88%;
      height: 80vh;
    }
  }
</style>
