<template>
  <div>
    <confirm-modal
    v-if="success"
    @confirm="goToHome"
    ModalTitle="Enviamos-te um e-mail com as instruções de como redefinires a tua senha.">
    <template #textButton>Fechar</template>
    </confirm-modal>
    <form class="Login" @submit.prevent="recoveryEmail">
      <div class="Wrapper" >
        <h1>Recuperar Senha</h1>
        <p v-if="error.hasError">{{error.message}}</p>
        <input type="email" placeholder="Nome do utilizador" v-model="email" required/>
        <button type="submit" ><span class="Next">enviar</span></button>
        <span class="create-count" @click="$router.go(-1)">
            <span>Voltar</span>
        </span>
      </div>
    </form>
  </div>
</template>
<script>
import { requestSendEmail } from '@/api/services/auth'
export default {
  name: 'RecoveryComponent',
  components: {
    'confirm-modal': require('@/components shared/Modal/ConfirmModal2').default
  },
  data () {
    return {
      email: '',
      success: false,
      error: {
        message: null,
        hasError: false
      }
    }
  },
  created () {
    document.title = 'Rise | Recuperação de palavra-passe'
  },
  methods: {
    goToHome () {
      this.success = false
      this.$router.push('/')
    },
    recoveryEmail () {
      requestSendEmail(this.email)
        .then(sucess => {
          this.success = true
        })
        .catch((e) => {
          this.error.hasError = true
          if (e.response) {
            this.error.message = e.response.data.message
          } else {
            this.error.message = 'Não foi possível estabelecer conexão, tente mais tarde!'
          }
        }).finally(() => {
          setTimeout(() => {
            this.error.hasError = false
          }, 4000)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.Login {
  width: 100%;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #4B335A;
  @media(max-width:560px){
    padding:0 5%!important;
    .create-count{
      margin-top: 25px!important;
    }
  }
  .Wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
     @media(max-width:560px){
         align-items: center;
    }
    h1 {
      color: #ffffff;
      padding: 20px;
      font-family: "NexaLight", sans-serif;
      font-size: 25px;
      font-weight: lighter;
      opacity: 1;
      letter-spacing: 0;
    }
    input::placeholder {
      color: #ffffff;
      opacity: 0.47;
    }
    input {
      background: #503660;
      padding: 30px 40px;
      border-radius: 6px;
      border: none;
      margin: 10px 0;
      width: 450px;
      color: #fff;
      font-size: 18px;
      font-family: "NexaLight", sans-serif;
      @media(max-width:560px){
        padding: 30px 20px!important;
        width: 100%!important;
      }
    }
    p {
      color: #ffffff;
      padding: 20px;
      font-family: "NexaLight", sans-serif;
      font-size: 18px;
      text-align: right;
      opacity: 0.5;
      cursor: pointer;
    }
    button {
      background: #6A4C7C;
      border-radius: 6px;
      padding: 30px 40px;
      border: none;
      margin: 10px 0;
      width: 450px;
      cursor: pointer;
      font-family: "NexaLight", sans-serif;
        @media(max-width:560px){
        width: 100%!important;
      }
    }
    span {
      color: #fdb00b;
      padding: 20px;
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      opacity: 0.9;
      cursor: pointer;
    }
    .Next {
      font-size: 18px;
      color: #fff;
      opacity: 0.9;
      letter-spacing: 0;
      font-family: "NexaLight", sans-serif;
    }
  }
  .toobar-container{
    width: 100%;
    height: auto;
    padding: 0 10%;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    @media(max-width:560px){
      padding: 0!important;
    }
  }
}
</style>
