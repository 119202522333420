 <template>
  <div>
    <recovery-password />
  </div>
</template>

<script>
import Login from '@/components/Auth/RecoveryPassword.vue'
export default {
  name: 'recovery',
  components: {
    'recovery-password': Login
  }
}
</script>
